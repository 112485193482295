import React from "react";
import Container from "react-bootstrap/Container";

const BottomBanner = ({ content, mainBg, position }) => {
	return (
		<div className="position-relative bg-primary angled-div">
			<div className="d-none d-md-block">
				<div
					style={{ zIndex: 2 }}
					className={`div-up-right  ${mainBg} position-absolute w-100 h-100 ${position} `}
				>
					<Container>{content}</Container>
				</div>
			</div>
			<div
				style={{ zIndex: 2 }}
				className={`div-up-right  ${mainBg} d-md-none `}
			>
				<Container>{content}</Container>
			</div>
		</div>
	);
};

export default BottomBanner;
