import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import SvgFanHp from "./svg/fan-svg-hp";

const TeamCard = ({
	img,
	imgAlt,
	name,
	role,
	text,
	bgColor,
	textColor,
	animId,
}) => {
	const pluginImage = getImage(img);

	return (
		<>
			<Col
				style={{ transform: "scale(0)" }}
				className={` ${animId} text-center d-none d-md-block pb-4`}
				md={6}
				lg={4}
			>
				{img !== null && (
					<BgImage
						style={{ height: "30rem" }}
						className="w-100 position-relative team-card"
						image={pluginImage}
					>
						{text !== null && (
							<div>
								<div
									style={{ zIndex: -1 }}
									className={`${bgColor} h-100 w-100 position-absolute top-0 start-0 team-card-bg text-white`}
								></div>
								<div
									className={` d-flex align-items-center text-start small-text p-4 h-100 w-100 team-card-text ${textColor}`}
								>
									{text}
								</div>
							</div>
						)}
					</BgImage>
				)}
				{img === null && (
					<div
						style={{ height: "30rem" }}
						className="position-relative team-card"
					>
						<SvgFanHp
							style={{ zIndex: -2, height: "auto", width: "100%" }}
							className="position-absolute  top-50 start-50 translate-middle "
						/>
						{text !== null && (
							<div>
								<div
									style={{ zIndex: -1 }}
									className={`${bgColor} h-100 w-100 position-absolute top-0 start-0 team-card-bg text-white`}
								></div>
								<div
									style={{ fontSize: "80%" }}
									className={` d-flex align-items-center text-start p-4 h-100 w-100 team-card-text ${textColor}`}
								>
									{text}
								</div>
							</div>
						)}
					</div>
				)}
				<p className="text-secondary shortstack pt-3 pb-0 mb-0 fs-3">{name}</p>
				<p className="text-secondary carnival-normal fs-1">{role}</p>
			</Col>
			<Col className="text-center d-md-none pb-4" md={6} lg={4}>
				{img !== null && (
					<BgImage
						style={{ height: "30rem" }}
						className="w-100 position-relative team-card"
						image={pluginImage}
					>
						{text !== null && (
							<div>
								<div
									style={{ zIndex: -1 }}
									className={`${bgColor} h-100 w-100 position-absolute top-0 start-0 team-card-bg text-white`}
								></div>
								<div
									className={` d-flex align-items-center text-start small-text p-4 h-100 w-100 team-card-text ${textColor}`}
								>
									{text}
								</div>
							</div>
						)}
					</BgImage>
				)}
				{img === null && (
					<div
						style={{ height: "30rem" }}
						className="position-relative team-card"
					>
						<SvgFanHp
							style={{ zIndex: -2, height: "auto", width: "100%" }}
							className="position-absolute  top-50 start-50 translate-middle "
						/>
						{text !== null && (
							<div>
								<div
									style={{ zIndex: -1 }}
									className={`${bgColor} h-100 w-100 position-absolute top-0 start-0 team-card-bg text-white`}
								></div>
								<div
									style={{ fontSize: "80%" }}
									className={` d-flex align-items-center text-start p-4 h-100 w-100 team-card-text ${textColor}`}
								>
									{text}
								</div>
							</div>
						)}
					</div>
				)}
				<p className="text-secondary shortstack pt-3 pb-0 mb-0 fs-3">{name}</p>
				<p className="text-secondary carnival-normal fs-1">{role}</p>
			</Col>
		</>
	);
};

export default TeamCard;
